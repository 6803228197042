import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { IConfiguration } from '@microsoft/applicationinsights-core-js'
import { IConfig } from '@microsoft/applicationinsights-common'
import router from '@/router/index'
import store from '@/store/index'
import { frontendVersion } from '@/store/GlobalStore'

type AppInsightsConfig = IConfiguration & IConfig

export class AppInsightHandler {
  static install(vue, config: AppInsightsConfig): void {
    vue.prototype.$appInsightHandler = new AppInsightHandler(config)
  }

  private appInsights: ApplicationInsights
  private baseName = 'STYLER'

  public constructor(config: AppInsightsConfig) {
    config.enableAutoRouteTracking = true
    this.appInsights = new ApplicationInsights({ config })
    this.appInsights.loadAppInsights()
    this.setupPageTracking()
  }

  /**
   * Sets up tracking between pages
   */
  private setupPageTracking() {
    router.beforeEach((to, from, next) => {
      const name = this.baseName + ' / ' + to.name
      this.appInsights.startTrackPage(name)
      next()
    })

    router.afterEach((route) => {
      const name = this.baseName + ' / ' + route.name
      const url = location.protocol + '//' + location.host + route.fullPath
      const properties = {
        user_id: store.state.global.profileId,
        organisation_id: store.state.global.context.selection.organisation.id,
        app_version: frontendVersion,
        platform: 'web',
      }
      this.appInsights.stopTrackPage(name, url, properties)
      this.appInsights.flush(true)
    })
  }
}
